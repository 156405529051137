<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div style="margin-top: 10px" class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.popup.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" type="text" style="margin-left: 15px !important" icon="el-icon-refresh" class="routeButton"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="bottom">
                    <el-button v-on:click="routePop('Pop-Up Tanımla')" type="text" style="margin-left: 15px !important" icon="el-icon-plus"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
            :element-loading-text='loading ? "Pop Listesi Yükleniyor. Lütfen Bekleyin." : "Pop Durumu Güncelleniyor. Lütfen Bekleyin."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-row class="mt-0 mb-10" >
                <el-col :lg="16" :md="16" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.popup.liste.yayinda")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button label="0">
                                <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.popup.liste.taslak")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>
                <el-col :lg="8" :md="8" :sm="24" :xs="24">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler">
                            <li class="">
                                <i class="mdi mdi-redo-variant"></i> Görüntüle
                            </li>
                            <li class="duzenle">
                                <i class="mdi mdi-pen"></i> {{$t("src.views.apps.popup.liste.duzenle")}}
                            </li>
                            <li v-if="selectIcon === '1'" class="sil">
                                <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.popup.liste.pasif")}}
                            </li>
                            <li v-else class="aktif">
                                <i class="mdi mdi-play"></i> {{$t("src.views.apps.popup.liste.yayinaAl")}}
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>
            <el-table :data="popupListe" stripe border ref="filterTable" style="width: 100%">
                <el-table-column
                    :label='$t("src.views.apps.genel.sira")'
                    type="index"
                    :index="indexMethod"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="popID"
                    :label='$t("src.views.apps.popup.liste.table.popID")'
                    width="70">
                </el-table-column>

                <el-table-column :label='$t("src.views.apps.urun.liste.table.image")'  width="100" align="center">
                    <template slot-scope="scope">
                        <el-popover transition="el-fade-in-linear" placement="right" width="auto" trigger="hover">
                            <img v-if="scope.row.resim" style="max-height: 600px" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <span v-else>Resim Yok.</span>
                            <img v-if="scope.row.resim" style="max-height: 50px !important" slot="reference" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="baslik"
                    :label='$t("src.views.apps.popup.liste.table.baslik")'
                    width="400">
                </el-table-column>
                <el-table-column 
                    prop="link"
                    :label='"Link"'
                    width="auto">
                </el-table-column>
                <el-table-column
                    prop="baslamaTarihi"
                    :label='$t("src.views.apps.popup.liste.table.baslamaTarihi")'
                    width="auto">
                </el-table-column>
                <el-table-column
                    prop="bitisTarihi"
                    :label='$t("src.views.apps.popup.liste.table.bitisTarihi")'
                    width="auto">
                </el-table-column>
                <el-table-column
                    :label='$t("src.views.apps.genel.islem")'
                    align="right"
                    width="150">
                    <template slot-scope="scope">
                        <!--
                        <el-tooltip content='Pop-up Görüntüle' :open-delay="500" placement="bottom">
                            <el-button
                                class="mr-10"
                                v-on:click="yeniSekmedeAc(path+scope.row.popLink)"
                                type="text"
                                style="color: #606266"
                                icon="mdi mdi-redo-variant mdi-24px">
                            </el-button>
                        </el-tooltip>
                        -->
                        <el-tooltip :content='$t("src.views.apps.popup.liste.duzenle")'
                                :open-delay="500"
                                placement="bottom">
                            <el-button
                                v-on:click="routePop('Popup Güncelle',scope.row)"
                                type="text"
                                style="color: #f7ba2a"
                                class="mr-20"
                                icon="mdi mdi-pen mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.popup.liste.pasif")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon=='1'"
                                @click="changePopDurum(scope.row, '0')"
                                class="buttonDel"
                                type="text"
                                icon="mdi mdi-trash-can mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content='$t("src.views.apps.popup.liste.pasif")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon=='0'"
                                @click="changePopDurum(scope.row, '1')"
                                type="text"
                                icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import popupService from '../../../WSProvider/PopService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import popService from '../../../WSProvider/PopService'

    export default {
        name: "PopupListe",
        mounted() {
            let self = this;

            EventBus.$on('popList', function(bool){
                if(bool){
                    self.page = 1;
                    self.postPage = 0;
                    self.getPopupListe();
                    self.getPopupCount();
                }
            });

            this.getPopupListe();
            this.getPopupCount();
        },
        data() {
            return {
                stateLoading: false,
                loading: false,
                imagepath: popService.imagePath,
                selectIcon: '1',
                radio: '1',
                path: "" ,
                sizes: 10,
                page: 0,
                postPage: 0,
                total: null,
                popupListe: [],
            }
        },
        methods: {
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getPopupListe();
                this.getPopupCount();
            },

            handleChange(event) {
                this.page = 1;
                this.postPage = 0;
                this.selectIcon = event;
                this.getPopupListe();
                this.getPopupCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },
            
            getPopupListe() {
                try{
                    this.loading = true;
                    popupService.popupListe(this.selectIcon, this.postPage, this.sizes).then(response => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(element => {
                                element = functions.removeSlashesAndDecodeStrings(element)
                            });
                            this.popupListe = response.data
                        }
                        this.loading = false
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.popupListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            getPopupCount() {
                try{
                    popupService.popupSayisi(this.selectIcon).then(response => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            this.total = response.data
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0;
                            }
                        }
                    })
                }catch(e){

                }
            },

            changePopDurum(selection, durum) {
                this.openConfirmDialog("Pop'un durumunu güncellemek istediğinizden emin misiniz?").then(() => {
                    try{
                        this.stateLoading = true;
                        popService.popDurumGuncelle(selection.popID, durum).then(response => {
                            if(response.status === 200){
                                localStorage.setItem("userDataDemirag", response.token)
                                this.getPopupListe();
                                this.getPopupCount();
                            }
                            notification.Status("success", this, response.msg);
                            this.stateLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false;
                    }
                })
            },

            yeniSekmedeAc(link){
                window.open(link)
            },

            routePop(name, scope) {
                if (scope) {
                    this.$store.commit('changePopData', scope);
                }
                this.routeSayfa(name)
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getPopupListe();
                this.getPopupCount();
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getPopupListe();
                this.getPopupCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }

</script>


<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }

    .kaldirilmis {
        background: #ec205f;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 10px
    }

</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>